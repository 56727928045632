import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export default function PrivacyRespected() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          indexPage {
            mainContent {
              privacyRespected {
                title
                text
                textMobile
              }
            }
          }
        }
      }
    }
  `)

  const content = data.site.siteMetadata.indexPage.mainContent.privacyRespected
  return (
    <div className="w-full bg-dark-gray relative p-6 md:p-10 lg:p-16 bg-cover">
      <img
        className="hidden md:block opacity-50 absolute top-0 left-0 right-0 bottom-0 h-full w-full object-cover"
        src={
          require("src/images/pages/index/main_content/privacy_respected/waves.svg")
            .default
        }
        alt="Background image showing the waves spreading"
      />
      <img
        className="block md:hidden opacity-50 absolute left-0 right-0 bottom-0 w-full"
        src={
          require("src/images/pages/index/main_content/privacy_respected/waves_mobile.svg")
            .default
        }
        alt="Background image showing the waves spreading"
      />
      <div className="flex flex-col items-center text-white text-center">
        <div className="flex flex-row items-center mb-8">
          <img
            className="mr-6"
            width="40"
            src={
              require("src/images/pages/index/main_content/privacy_respected/shield.svg")
                .default
            }
            alt="Shield icon"
          />
          <span className="section-title">{content.title}</span>
        </div>
        <div
          className="hidden md:block font-light"
          dangerouslySetInnerHTML={{ __html: content.text }}
        />
        <div
          className="block md:hidden font-light"
          dangerouslySetInnerHTML={{
            __html: content.textMobile || content.text,
          }}
        />
      </div>
    </div>
  )
}
