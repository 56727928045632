import {graphql, useStaticQuery} from "gatsby";
import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import ValueBlock from "src/components/common/value_block";
import PrivacyRespected from "src/components/pages/index/privacy_respected";

export default function MainContent() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          indexPage {
            mainContent {
              values {
                block1 {
                  items {
                    imageName
                    title
                    text
                    alt
                    detailsUrl
                  }
                  textFirst
                }
                block2 {
                  items {
                    title
                    text
                    carouselItems {
                      imageName
                      alt
                    }
                  }
                  textFirst
                }
                block3 {
                  items {
                    imageName
                    title
                    text
                    alt
                  }
                  textFirst
                }
                block4 {
                  items {
                    imageName
                    title
                    text
                    textMobile
                    alt
                    auxiliaryImages {
                      imagePath
                      alt
                      width
                      height
                    }
                    detailsUrl
                  }
                  textFirst
                }
                block5 {
                  items {
                    title
                    text
                    carouselItems {
                      imageName
                      alt
                    }
                  }
                  textFirst
                }
              }
            }
          }
        }
      }
      valuesImages: allFile(
        filter: {relativeDirectory: {eq: "pages/index/main_content/values"}}
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: {fit: COVER}, quality: 80)
            }
          }
        }
      }
    }
  `);

  const block1 = data.site.siteMetadata.indexPage.mainContent.values.block1;
  const block2 = data.site.siteMetadata.indexPage.mainContent.values.block2;
  const block3 = data.site.siteMetadata.indexPage.mainContent.values.block3;
  const block4 = data.site.siteMetadata.indexPage.mainContent.values.block4;
  const block5 = data.site.siteMetadata.indexPage.mainContent.values.block5;

  return (
    <div>
      <ValueBlock
        items={block1.items}
        allImages={data.valuesImages.edges}
        textFirst={block1.textFirst}
      />
      <PrivacyRespected />
      <ValueBlock
        items={block2.items}
        allImages={data.valuesImages.edges}
        textFirst={block2.textFirst}
      />
      <ValueBlock
        items={block3.items}
        allImages={data.valuesImages.edges}
        textFirst={block3.textFirst}
      />
      <ValueBlock
        items={block4.items}
        allImages={data.valuesImages.edges}
        textFirst={block4.textFirst}
      />
      <ValueBlock
        items={block5.items}
        allImages={data.valuesImages.edges}
        textFirst={block5.textFirst}
      />
    </div>
  );
}
