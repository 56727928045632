import React, {useState, useEffect} from "react";
import Header from "src/components/common/header";
import Hero from "src/components/pages/index/hero";
import MainContent from "src/components/pages/index/main_content";
import CommonHelmet from "src/components/common/helmet";
import Footer from "src/components/common/footer";
import {incrementWithOverflow} from "src/helpers/numbers";

// markup
export default function IndexPage() {
  const activateTimer = () =>
    setTimerId(
      setInterval(() => {
        setSelectedItemId((selectedId) => incrementWithOverflow(selectedId, 4));
      }, 4000)
    );

  const [selectedItemId, setSelectedItemId] = useState(1);

  const [timerId, setTimerId] = useState(undefined);
  if (timerId === undefined) {
    activateTimer();
  }

  useEffect(() => {
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <main className="font-sans text-black flex flex-col items-center justify-center">
      <div className="max-w-7xl relative">
        <CommonHelmet />
        <Header
          onLogoClick={() => {
            clearInterval(timerId);
            setSelectedItemId(1);
            activateTimer();
          }}
          heroStyle="dark"
        />
        <Hero
          selectedHeroItemId={selectedItemId}
          onUserInput={(itemId) => {
            setSelectedItemId(itemId);
            clearInterval(timerId);
          }}
        />
        <MainContent />
        <Footer />
      </div>
    </main>
  );
}
