import {graphql, Link, useStaticQuery} from "gatsby";
import React, {useState} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import ResponsiveGatsbyImage from "src/components/common/responsive_image";

export default function Hero(props) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          indexPage {
            heroItems {
              id
              title
              imageName
              gotoUrl
            }
            heroThumbnails {
              title
              imageName
              heroId
            }
          }
        }
      }
      allFile(filter: {relativeDirectory: {eq: "pages/index/heroItems"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: {fit: COVER}, quality: 60)
            }
          }
        }
      }
    }
  `);

  const selectableHeroItem = (item, isSelected) => (
    <div
      key={`hero-thumbnail-${item.heroId}`}
      onClick={() => props.onUserInput(item.heroId)}
      className="cursor-pointer mx-4 flex flex-col items-center"
    >
      <div className="relative mb-2 border-white border-solid border-2 rounded-lg">
        <GatsbyImage
          className="w-24 lg:w-36 rounded-lg"
          alt={item.title}
          image={
            data.allFile.edges.find((el) => el.node.name === item.imageName)
              .node.childImageSharp.gatsbyImageData
          }
        />
        <div
          className={`absolute top-0 w-full h-full rounded-lg bg-black bg-opacity-50 ${
            isSelected ? "hidden" : "block"
          }`}
        />
      </div>
      <span className="text-white text-xs sm:text-sm">{item.title}</span>
    </div>
  );

  const heroBlock = (item, isSelected) => (
    <div
      className={`transition-opacity duration-500 col-start-1 col-end-2 row-start-1 row-end-2 relative mb-2 h-full ${
        isSelected ? "opacity-100 z-10" : "opacity-0 -z-10"
      }`}
      key={`hero-${item.id}`}
    >
      <Link to={item.gotoUrl ?? "/#"}>
        <ResponsiveGatsbyImage
          alt={item.title}
          files={data.allFile.edges}
          fadeIn={false}
          image={item.imageName}
        />
        {/* <!-- Hero title --> */}
        <div className="absolute bottom-28 lg:inset-0 w-full lg:flex lg:items-center">
          {/* <!-- Desktop view --> */}
          <div className="hidden md:flex px-6 w-1/2 xl:w-1/3 ">
            <span className="text-white text-3xl lg:text-5xl lg:leading-normal leading-normal mb-4 lg:mb-8">
              {item.title}
            </span>
          </div>
          {/* <!-- Mobile view --> */}
          <div className="md:hidden px-8 mb-6 w-full">
            <span className="text-white text-3xl leading-normal mb-4">
              {item.title}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );

  return (
    <div className="overflow-hidden">
      <div className="relative">
        {/* <!-- Hero --> */}
        <div className="grid grid-cols-1 grid-rows-1">
          {data.site.siteMetadata.indexPage.heroItems.map((el, index) =>
            heroBlock(el, el.id === props.selectedHeroItemId)
          )}
          {/* <!-- Selectable hero items --> */}
          <div className="absolute bottom-8 flex items-center justify-center flex-row w-full z-20">
            {data.site.siteMetadata.indexPage.heroThumbnails.map((item) =>
              selectableHeroItem(item, item.heroId === props.selectedHeroItemId)
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
